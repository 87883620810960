import * as React from 'react';
import { filter as _filter, map as _map } from 'lodash';
import { Container } from 'theme-ui';
import { Box, Flex } from '@lce/slice_v2';

import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import { MapIdProvider } from '@lce/intl-util/src/api/SelectedMapId';
import Map from '@lce/intl-ui/src/PageComponents/ContactUs/Map';
import PageTitle from '@lce/intl-ui/src/Elements/PageTitle';
import StoreLocation from '@lce/intl-ui/src/PageComponents/ContactUs/StoreLocationsList/components/StoreLocation';
import useLocation from '../../api/ContactPage/useLocation';
import useSiteConfig from '../../api/Config/useSiteConfig';
import Layout from '../../ui/ConnectedLayout';
import { useUpdateFranchiseeId } from '../../api/FranchiseeId/hooks/useUpdateFranchiseeId';

export interface IStoreDetails extends IDefaultPageProps { 
  pageContext: {
    originalPath?: string;
    franchisee: {
      name: string;
      originalId: string;
    };
  };
}

const StoreDetails: React.FC<IStoreDetails> = ({ ...props }) => {
  const isBrowser = typeof window !== 'undefined';
  const { locale } = props.pathContext;
  const { franchisee } = props.pageContext;
  const [ setFranchiseeId ] = useUpdateFranchiseeId();

  const slug = isBrowser ? window.location.href.split('/').pop() : '';

  React.useEffect(() => {
    setFranchiseeId(franchisee.originalId);
  }, [franchisee.originalId]);

  const locationData = useLocation(locale);
  const { locationDefault, locationsList } = locationData;
  const { siteConfig: { googleMapKey }, direction } = useSiteConfig(locale);

  const location = _filter(locationsList, (store) => store.slug === `/${slug}`)[0];
  const mapLocation = _filter(locationData.mapDisplay.locations, (store) => store.slug === `/${slug}`)[0];
  locationData.mapDisplay.locations = [];
  locationData.mapDisplay.locations.push(mapLocation);
  const mapDisplay = locationData.mapDisplay;

  return (
    <Layout {...props}>
      <MapIdProvider>
        <Container data-testid="store-details" sx={{ maxWidth: '1280px', px: '16px' }}>
          <PageTitle>{locationDefault.pageTitle}</PageTitle>
          <Flex sx={{
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            pb: '1.5em',
          }}>
            <Map apiKey={googleMapKey} { ...mapDisplay } />
            <Box sx={{ width: ['100%', '50%'] }}>
              <Box
                sx={{
                  p: [null, direction === 'rtl' ? '0 88px 0 20px' : '0 20px 0 88px'],
                  pt: '20px',
                  width: '100%',
                  height: 'auto',
                }}
              >
                <StoreLocation {...location} {...locationDefault} />
              </Box>
            </Box>
          </Flex>
          {locationData.legalContent.legalText &&
            <Box
              dangerouslySetInnerHTML={{ __html: locationData.legalContent.legalText }}
              sx={{ fontFamily: 'tertiary', fontSize: '16px' }}
            />}
        </Container>
      </MapIdProvider>
    </Layout>
  );
};

export default StoreDetails;
